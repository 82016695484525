'use client'
import { SearchType } from '@/app/(site)/search/types'
import { giphyUrl } from '@/app/util/env'
import { SearchTypeToFetchType } from '@/app/util/search-util'
import { useGiphyFetch } from '@/app/util/use-gf'
import MediumRectangleAd from '@/components/ads/medium-rectangle'
import Grid from '@/components/grid/grid'
import PaginationCarousel from '@/components/pagination-carousel'
import { SectionHeader } from '@/components/sections'
import UAParserContext from '@/context/ua-parser'
import { Interface, NexaBlack } from '@/styles/fonts'
import { relativeGifClick } from '@/util/url'
import { giphyBlack } from '@giphy/colors'
import { IGif } from '@giphy/js-types'
import { Gif as _Gif, VideoOverlay as _VideoOverlay, Carousel, GifOverlayProps } from '@giphy/react-components'
import { useContext, useRef } from 'react'
import styled, { css } from 'styled-components'
import _PageTitle from 'ui/src/components/page-title'
import { desktop } from 'ui/src/css'
import Koko from '../../search/koko'
import { KokoResponse } from '../../search/koko/api'

const desktopClipWidth = 248
const mobileWidth = 161
const aspectRatio = 16 / 9

const PageTitle = styled(_PageTitle)`
    font-family: ${Interface.style.fontFamily};
    ${desktop(css`
        margin: 21px 0;
        font-size: 36px;
        font-family: ${NexaBlack.style.fontFamily};
    `)}
`

const Gif = styled(_Gif)`
    img {
        object-fit: cover;
    }
`

const VideoOverlay = styled(_VideoOverlay)`
    video {
        background: ${giphyBlack};
        object-fit: contain;
    }
`

const getTrackingUrl = (page: number, term: string) => `/search/${term}/${page}`

type Props = {
    type: SearchType
    decodedTerm: string
    initialGifs?: IGif[]
    initialClips?: IGif[]
    kokoResponse?: KokoResponse
}
const Contents = ({ type, decodedTerm, initialGifs, initialClips, kokoResponse }: Props) => {
    const { current: initialSearchTerm } = useRef(decodedTerm)
    const { deviceType } = useContext(UAParserContext)
    const gf = useGiphyFetch()

    function setClipDimensions() {
        const clipWidth = deviceType === 'desktop' ? desktopClipWidth : mobileWidth
        return { clipWidth, clipHeight: clipWidth / aspectRatio }
    }
    const { clipWidth, clipHeight } = setClipDimensions()
    const typeString = type === 'gifs' ? 'GIFs' : 'Stickers'
    return (
        <>
            <PageTitle>
                Explore <PageTitle.HighLight>{initialSearchTerm}</PageTitle.HighLight> {typeString}
            </PageTitle>
            {!!initialClips &&
                (deviceType === 'mobile' ? (
                    <>
                        <Koko preFetchedResponse={kokoResponse} term={initialSearchTerm} />
                        <SectionHeader label={`GIPHY Clips`} />
                        <Carousel
                            gifWidth={clipWidth}
                            gifHeight={clipHeight}
                            initialGifs={initialClips}
                            fetchGifs={(offset: number) =>
                                gf.search(initialSearchTerm, {
                                    offset,
                                    type: 'videos',
                                    limit: 15,
                                    rating: 'r',
                                })
                            }
                            onGifClick={(gif, e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                location.href = `${giphyUrl}${new URL(gif.url).pathname}`
                            }}
                            overlay={(props) => <VideoOverlay {...props} width={clipWidth} />}
                        />
                    </>
                ) : (
                    <>
                        <MediumRectangleAd />
                        <Koko preFetchedResponse={kokoResponse} term={initialSearchTerm} />
                        <PaginationCarousel title={<h4 className="">GIPHY Clips</h4>}>
                            {initialClips.map((gif: IGif) => (
                                <Gif
                                    key={gif.id}
                                    overlay={(props: GifOverlayProps) => <VideoOverlay {...props} width={clipWidth} />}
                                    gif={gif}
                                    width={clipWidth}
                                    height={clipHeight}
                                    onGifClick={(gif, event) => {
                                        event.stopPropagation()
                                        event.preventDefault()
                                        relativeGifClick(gif, event)
                                    }}
                                />
                            ))}
                        </PaginationCarousel>
                    </>
                ))}
            <SectionHeader label={`Explore ${typeString}`} />
            {!!initialGifs && (
                <Grid
                    initialGifs={initialGifs}
                    key={initialSearchTerm}
                    fetchGifs={async (offset: number) =>
                        gf.search(initialSearchTerm, {
                            offset,
                            limit: 15,
                            type: SearchTypeToFetchType[type],
                        })
                    }
                    gaTrackingUrl={(page) => getTrackingUrl(page, initialSearchTerm)}
                    noShowMessage={`No ${typeString} found for ${initialSearchTerm}.`}
                />
            )}
        </>
    )
}

export default Contents
